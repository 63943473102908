.myPageWrapper {
    text-align: center;
}

.typedDemo {
    font-size: x-large;
}

section {
    margin-top: 50px;
}